"use strict";

// tohle nahraje i soubory, ktere nejsou primo linkovane z entrypointu
import "./images/*.{png,jpg,jpeg,gif,svg}";

// UIkit
import UIkit from "uikit";
import Icons from "uikit/dist/js/uikit-icons";

// loads the Icon plugin
UIkit.use(Icons);

// import stylu
import "./index.scss";

// Sentry.io integrace
/**
import * as Sentry from "@sentry/browser";
import { Integrations } from "@sentry/tracing";

Sentry.init({
   dsn: "https://3f0f6d753f76e0c529d9dca5c4cf9c3b@o4505839489253376.ingest.sentry.io/4505839495151616",

   // Alternatively, use `process.env.npm_package_version` for a dynamic release version
   // if your build tool supports it.
   release: "kher",
   integrations: [new Integrations.BrowserTracing()],

   // Set tracesSampleRate to 1.0 to capture 100%
   // of transactions for performance monitoring.
   // We recommend adjusting this value in production
   tracesSampleRate: 1.0,

});
*/

/* MENU OPENER START */
const openButton = document.getElementById("mainMenuOpener");
const openMenu = document.getElementById("mainMenu");

openButton.addEventListener("click", (event) => {
   if (openMenu.classList.contains("open")) {
      openMenu.classList.remove("open");
   } else {
      openMenu.classList.add("open");
   }
});
/* MENU OPENER STOP */

/* TABS OPENER START */
const tab = document.getElementById("tabs");

if (tab) {
	document.querySelectorAll(".tab__opener__btn").forEach(function (elem) {
		elem.addEventListener("click", function () {
			openTab(elem.dataset.opener);
		});
	});
}

function openTab(idO) {
   document.querySelectorAll(".tabs__content").forEach(function (elem) {
		if (!elem.classList.contains("hidden")) {
			elem.classList.add("hidden");
		}
   });
   document.getElementById(idO).classList.remove("hidden");
   document.querySelectorAll(".tab__opener__btn").forEach(function (elem) {
			if (elem.classList.contains("active")) {
				elem.classList.remove("active");
			}
		});
   document.querySelectorAll("[data-opener='" + idO + "']").forEach(function (elem) {
      elem.classList.add("active");
   });
};
/* TABS OPENER STOP */

/* lightbox (GLightbox) */
import GLightbox from 'glightbox';
const lightbox = GLightbox({
   touchNavigation: true,
   loop: true,
   autoplayVideos: true
});

// slider
import 'keen-slider/keen-slider.min.css'
import KeenSlider from 'keen-slider'
document.addEventListener("DOMContentLoaded", () => {
   document.querySelectorAll('.keen-slider').forEach(elm => {
      elm.slider = new KeenSlider(elm, {loop: true});
   });
});


// lazy nacitani youtube
document.addEventListener("DOMContentLoaded", () => {
   setTimeout(() => {
      document.querySelectorAll('[data-lazy-youtube]').forEach(container => {
         const iframe = document.createElement("iframe");
         const attrs = JSON.parse(container.dataset.lazyYoutube);
         for (const attr in attrs) {
            let value = attrs[attr];
            if (value == "false") {
               value = false;
            }
            if (value == "" || value == "true") {
               value = true;
            }
            iframe.setAttribute(attr, value);
         }
         container.appendChild(iframe);
      });
   }, 2000)
});
